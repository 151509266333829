#HomeNav {
    background: linear-gradient(270deg, #000000 10%, #8d00ff 80%, #00ff4d 100%);
    background-size: 400% 400%;

    -webkit-animation: gardientAnimation 10s ease infinite;
    -moz-animation: gardientAnimation 10s ease infinite;
    -o-animation: gardientAnimation 10s ease infinite;
    animation: gardientAnimation 10s ease infinite;
    border: 1px solid rgba(200, 200, 200, .8)!important;
    border-radius: 5px!important;
    padding-top: 7px!important;
}

#HomeNav:hover{
    background: linear-gradient(270deg, #8d00ff 10%, #000000 80%, #00ff4d 100%);
    background-size: 400% 400%;

    -webkit-animation: gardientAnimation 3s ease infinite;
    -moz-animation: gardientAnimation 3s ease infinite;
    -o-animation: gardientAnimation 3s ease infinite;
    animation: gardientAnimation 3s ease infinite;
    border: 1px solid rgba(200, 200, 200, .8)!important;
    border-radius: 5px!important;
    padding-top: 7px!important;
}  


#bottomButton {
    background: linear-gradient(270deg, #000000 10%, #8d00ff 80%, #00ff4d 100%);
    background-size: 400% 400%;
    border-radius: 10px;
    -webkit-animation: gardientAnimation 10s ease infinite;
    -moz-animation: gardientAnimation 10s ease infinite;
    -o-animation: gardientAnimation 10s ease infinite;
    animation: gardientAnimation 10s ease infinite; 
}

#bottomButton:hover {
    background: linear-gradient(270deg, #000000 10%, #8d00ff 80%, #00ff4d 100%);
    background-size: 400% 400%;
    border-radius: 10px;
    -webkit-animation: gardientAnimation 3s ease infinite;
    -moz-animation: gardientAnimation 3s ease infinite;
    -o-animation: gardientAnimation 3s ease infinite;
    animation: gardientAnimation 3s ease infinite; 
}


@-webkit-keyframes gardientAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes gardientAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-o-keyframes gardientAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes gardientAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

a:link {
    color: hotpink;
    text-decoration: none; 
} 
a:visited {
    color: hotpink;
    text-decoration: none; 
} 
a:hover {
    color: hotpink;
    text-decoration: none; 
} 
a:active {
    color: hotpink;
    text-decoration: none; 
}


/* #HomeNav {
    background: linear-gradient(-90deg, rgba(13,124,50,1) 0%, rgba(87,19,106,1) 60%, rgba(0,0,0,1) 100%);
    border: 1px solid rgba(200, 200, 200, .8)!important;
    border-radius: 5px!important;
    padding-top: 7px!important;
}

#HomeNav:hover{
    background: rgb(0,0,0);
    background: linear-gradient(-90deg, rgba(17,190,75,1) 4%, rgba(200,1,255,1) 37%, rgba(0,0,0,1) 100%);
    animation: gradient 15s ease infinite;
}  */


#Search {
    color: rgb(255, 255, 255);
    border: 1px solid;
    border-radius: 5px!important;
    padding: 10px;
    padding-top: 13px;
    margin: 0px;
    background-color: rgba(255, 255, 255, 0.26);
}


#Search:active {
    color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.603);
}

#Search:hover {
    color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.603);
}


.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* a high value to ensure it is on top */
  }